
import { Component, Vue, Prop, Emit, Watch } from "vue-property-decorator";
import { ValidationProvider } from "vee-validate";
import { FormTextarea } from "@/components/forms";

@Component({
    components: {
        FormTextarea,
        ValidationProvider,
    },
})
export default class PropertyPartBuildingState extends Vue {
    @Prop({ default: null }) value!: string | string[] | null;
    @Prop({ default: false }) disabled!: boolean;

    localValue: string[] = [""];

    addOtherCondition() {
        this.localValue.push("");
    }

    removeOtherCondition(index: number) {
        this.localValue.splice(index, 1);

        this.handleInput();
    }

    @Emit("input")
    handleInput(): string[] {
        return this.localValue;
    }

    @Watch("value", { immediate: true })
    valueChanged(newValue: string | string[] | null) {
        if (typeof newValue === "string") {
            this.localValue = [newValue];
        } else if (Array.isArray(newValue)) {
            this.localValue = newValue;
        } else {
            this.localValue = [""];
        }
    }
}
